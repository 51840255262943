import {
    IonPage,
    IonContent,
    useIonAlert,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
} from "@ionic/react";
import { close } from 'ionicons/icons';
import { useState } from 'react';
import UseBranding from '../../Helpers/Hooks/UseBranding';
import { userRegister } from "../User/UserStore";
import MWButton from "../../Components/MWButton/MWButton";
import IFrameModalController from '../../Components/IframeModal/IframeModalController';

const Email = ({
    ...props
}: {
    onDismiss: Function;
}) => {
    const [loading, setLoading] = useState(false);
    const [showMessage] = useIonAlert();
    const [localEmail, setLocalEmail]: any = useState(null);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const registerIfProvided = async () => {
        if (!localEmail || localEmail === '') {
            props.onDismiss();
        } else {
            try {
                setLoading(true);
                await userRegister(null, null, localEmail, null, null, null);
                props.onDismiss();
                setLoading(false);
            } catch (e) {
                const error: any = e;
                console.error('error setting user', error);
                showMessage(error.message).then();
            }
            setLoading(false);
        }

    };

    return (
        <IonPage className='h-full'>
            <IonHeader className="sticky top-0">
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonButton
                            fill='clear'
                            slot='start'
                            onClick={() => props.onDismiss()}
                        >
                            <IonIcon icon={close} slot='icon-only' />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Welcome</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <main className='bg-white py-4 px-4'>
                    <div className="flex flex-col items-center justify-center bg-white">
                        <img
                            className='max-w-none'
                            src={UseBranding()}
                            alt='Market Wagon Logo'
                            width='80px'
                            height='80px'
                        />
                        <h1 className='text-base font-bold text-center leading-tight mt-5 md:text-2xl'>
                            Welcome to Market Wagon,<br />
                            the online farmer's market that delivers.
                        </h1>
                        <p className='text-gray-800 text-center px-6 md:px-10 mt-8 text-sm md:text-base'>
                            Please enter your email address to keep up to date with Market Wagon.
                        </p>
                        <div
                            className='mt-4 w-full max-w-sm'>
                            <input
                                type='tel'
                                placeholder='Email address (optional)'
                                style={{ fontSize: '18px' }}
                                className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg login-form'
                                value={localEmail || ''}
                                onChange={(evt: any) => {
                                    setLocalEmail(evt.target.value);
                                }}
                            />

                        </div>
                        <div className='mt-8 w-full max-w-sm'>
                            <MWButton
                                title='Shop Market Wagon'
                                className='justify-center w-full text-white bg-green-600 rounded-lg h-11'
                                onClick={() => {
                                    registerIfProvided().then();
                                }}
                            >
                                {!loading ? (
                                    <div>Shop Market Wagon</div>
                                ) : (
                                    <div>Loading...</div>
                                )}
                            </MWButton>
                        </div>
                    </div>
                </main>
                <div className='flex justify-center px-4 mt-10 space-x-3 text-xs flex-fixed legal'>
                    <button
                        className='underline'
                        title='View Terms of Service'
                        onClick={() => {
                            setShowTermsModal(true);
                        }}
                    >
                        Terms of Service
                    </button>

                    <button
                        className='underline'
                        title='View Privacy Policy'
                        onClick={() => {
                            setShowPrivacyModal(true);
                        }}
                    >
                        Privacy Policy
                    </button>
                </div>
            </IonContent>

            <IFrameModalController
                onDismiss={() => {
                    setShowTermsModal(false)
                }}
                title="Terms &amp; Conditions"
                url="https://marketwagon.com/pages/conditions-of-use"
                open={showTermsModal}
            />

            <IFrameModalController
                onDismiss={() => {
                    setShowPrivacyModal(false)
                }}
                title="Privacy"
                url="https://marketwagon.com/privacy.php"
                open={showPrivacyModal}
            />
        </IonPage>
    );
};
export default Email;
