import {Store} from 'pullstate';
import {createSelector} from 'reselect';
import {
  addToCartPost,
  bulkAddToCartPost,
  fetchAutoTipAmount,
  getCart,
  getSubscribedProducts,
  getSubscriptionStatus,
  removeFromCartPost,
  subscribeToProduct,
  unsubscribeFromProduct,
  updateAutoTip,
} from '../../Api/api.cart';
import {ProductType} from '../Product/ProductClass';
import CartItem from './CartItemClass';
import {CartItemType, CartStoreStateType} from './types';

const starterState: CartStoreStateType = {
  items: [],
  showSubscriptionModal: false,
  subscriptionEnabled: false,
  initialized: false,
  loading: true,
  subscriptionState: null,
  activeOrder: null,
};

export const CartStore = new Store(starterState);

const getState = (state: any): CartStoreStateType => state;

export const isCartInitialized = createSelector(getState, (state) => {
  return state.initialized;
});

/**
 * Initialize the Cart Store
 */
export const CartStoreInit = async () => {
  try {
    CartStore.update((s) => {
      s.loading = true;
    });

    const existingItems = await getCart();

    if (existingItems) {
      CartStore.update((s) => {
        s.items = existingItems;
      });
    }
  } catch (e) {
    console.error('Error fetching existing cart');
  }

  CartStore.update((s) => {
    s.loading = true;
    s.items = s.items || [];
  });

  try {
    const res: any = await getSubscriptionStatus();

    // Update the Store
    CartStore.update((s) => {
      s.subscriptionEnabled = res.enabled;
      s.loading = false;
      s.subscriptionState = res.status;
    });
  } catch (e) {
    console.error(e);

    CartStore.update((s) => {
      s.loading = false;
      s.subscriptionEnabled = false;
      s.subscriptionState = null;
    });
  }
};

export const getSubscriptions = async (): Promise<Array<CartItem>> => {
  try {
    return await getSubscribedProducts().catch((e) => {
      throw e;
    });
  } catch (e) {
    console.error('Error loading user subscriptions');

    console.error(e);
  }
  return [];
};

export const subscriptionsAdd = async (
  productId: any,
  variantId: any,
  qty: number = 1,
  interval: number = 1
): Promise<boolean> => {
  try {
    await subscribeToProduct(productId, variantId, qty, interval);

    CartStore.update((s) => {
      s.showSubscriptionModal = true;
    });

    return true;
  } catch (e) {
    console.error('Error subscribing to product');
    console.error(e);
    return false;
  }
};

/**
 * Remove a product subscription
 * @param productId
 * @param variantId
 * @returns
 */
export const subscriptionRemove = async (
  productId: any,
  variantId: any
): Promise<boolean> => {
  try {
    await unsubscribeFromProduct(productId, variantId);

    return true;
  } catch (e) {
    console.error('Error unsubscribing from product]');
    console.error(e);

    return false;
  }
};

export const addAutoTip = async (tipPercentage: number): Promise<any> => {
  try {
    await updateAutoTip(tipPercentage);
  } catch (e) {
    console.error('Error adding Tip');
    console.error(e);

    return false;
  }
};

export const getAutoTipAmount = async (): Promise<any> => {
  try {
    return fetchAutoTipAmount();
  } catch (e) {
    console.error('Error fetching Tip');
    console.error(e);

    return false;
  }
};

/**
 * Change Product Variant Qty
 * @param cartItem
 * @param amount
 */
export const changeQty = async (cartItem: CartItem, amount: number) => {
  const newQty = cartItem.quantity + amount;

  // Add to Cart from the Cart Store should update automatically
  await updateCart({
    productId: cartItem.product.id,
    qty: newQty,
    variantId: cartItem.product.variant.id,
  });
};

/**
 * Get Cart Subtotal
 * Returns a string formatted version of the sub total
 *
 * @returns string
 */
export const getCartSubTotal = function (): string {
  let subtotal: string = '';
  const state: CartStoreStateType = CartStore.getRawState();
  if (state.items.length > 0) {
    subtotal =
      '$' +
      state.items
        .map((item: CartItem) => {
          return ((item.product.variant.salePrice ? item.product.variant.salePrice : item.product.variant.price) || 0) * (item.quantity || 0);
        })
        .reduce((a, b) => {
          return a + b;
        })
        .toFixed(2);
    return subtotal;
  } else {
    return '';
  }
};

// Define Cart Props
type AddToCartProps = { productId: number; qty: number; variantId: number };

/**
 * Add Product to Cart
 * Will add a product, qt, and variant to a cart
 *
 * @param object
 */
export const addToCart = async ({
                                  productId,
                                  qty,
                                  variantId,
                                }: AddToCartProps) => {
  let {items} = CartStore.getRawState();
  let correspondingCartItem = items.find(
    (item) => item.product.variant.id === variantId
  );

  if (correspondingCartItem) {
    qty += correspondingCartItem.quantity;
  }
  await updateCart({
    productId: productId,
    qty: qty,
    variantId: variantId,
  });
};

const updateCart = async ({
                            productId,
                            qty,
                            variantId,
                          }: AddToCartProps): Promise<boolean | undefined> => {
  try {
    const updatedCart: Array<CartItemType> = await addToCartPost(
      productId,
      variantId,
      qty
    );

    if (updatedCart) {
      // Update the store with the API response since it sense back the cart
      CartStore.update((state: CartStoreStateType) => {
        state.items = updatedCart.map((item: CartItemType) => {
          return new CartItem(item);
        });
      });
    }

    return true;
  } catch (e: any) {
    console.error(e);
    return false;
  }
};

export const bulkCartAdd = async (
  addProductList: { variantId: number; quantity: number, fromBundleId: number | null }[]
) => {
  try {
    const bulkUpdatedCart: Array<CartItemType> = await bulkAddToCartPost(
      addProductList
    );

    if (bulkUpdatedCart) {
      // Update the store with the API response since it sense back the cart
      CartStore.update((state: CartStoreStateType) => {
        state.items = bulkUpdatedCart.map((item: CartItemType) => {
          return new CartItem(item);
        });
      });
    }
    return true;
  } catch (e: any) {
    console.error(e);
    return false;
  }
};

/**
 * Remove from Cart
 * Removes and item from the current Cart
 * @param cartItem
 */
export const removeFromCart = async (cartItem: CartItem) => {
  CartStore.update((d) => {
    d.items = d.items.filter((item: CartItem) => {
      return item.id !== cartItem.id;
    });
  });

  const cartMinusItem = await removeFromCartPost(
    cartItem.product.id,
    cartItem.product.variant.id,
    0
  );

  CartStore.update((s) => {
    s.items = cartMinusItem;
  });
};

/**
 * Subscribe to a Product
 * @param product
 * @param variantId
 * @param qty
 */
export const subscribe = async (
  product: ProductType,
  variantId: string,
  qty: number = 1
) => {
  const payload = {productId: product.id, variantId, qty};
  console.info('Subscribe TODO:// make this real ', payload);
};

export const setActiveOrder = (order: any) => {
  try {
    CartStore.update((s) => {
      s.activeOrder = order;
    })
  } catch (e) {
    console.error('Error setting active order');
  }
}
