import {IonItem} from '@ionic/react';
import dayjs from 'dayjs';
import Qty from '../../Components/Qty/Qty';
import {changeQty} from './CartStore';
import {CartItemProps} from './types';

// TODO: Need to make Cart Items get a real product - how do we do that?
const CartListItem = ({...props}: CartItemProps) => {
  const item: any = props.item;
  const itemPrice = item?.product.variant.salePrice
    ? item.product.variant.salePrice
    : item?.product.variant.price;

  return (
    <IonItem
      lines='none'
      color='transparent'
      className={`${props.itemClass || ''} pt-2 pb-3`}
    >
      <button
        aria-label='View Details'
        onClick={() => {
          if (props.onOpenProduct) {
            props.onOpenProduct(item?.toProduct);
          }
        }}
        className='w-12 h-12 mr-2 bg-gray-200 bg-center bg-cover rounded-md shadow-md flex-fixed imageholder'
        style={{
          backgroundImage: `url(${
            item?.product?.images
              ? item.product.images[0]
              : '/images/default-image.svg'
          })`,
        }}
      />

      <div className='w-full py-3 pr-2 ion-text-wrap'>
        {item?.vendor && (
          <p className='text-xs line-clamp-1 opacity-70'>{item.vendor.name}</p>
        )}

        <div className='mb-1 text-base text-black font-semibold leading-tight break-words'>
          {item?.watching && (
            <div className=' text-sm text-red-500 font-black'>SOLD OUT</div>
          )}

          {item?.product?.isPreOrder &&
            `${dayjs(item?.product?.variant?.preOrderDate.split('T')[0]).format('MM/DD/YYYY')} - `
          }

          {item?.product.title} {item?.product.variant.title.toLowerCase() !== 'each' ? item?.product.variant.title : ''}
        </div>
      </div>

      <div className='flex flex-col items-center text-black justify-center'>
        <span className='mb-1 text-base font-semibold price'>
          ${((itemPrice || 0) * (item?.quantity || 0)).toFixed(2)}
        </span>

        {
          <Qty
            slot='end'
            value={item?.quantity}
            max={item?.product.variant.inventory}
            onMore={() => {
              // changeQty(item, 1);
              if (props.onChangeQty) {
                props.onChangeQty(1);
              }
            }}
            onLess={() => {
              if ((item?.quantity || 0) > 1) {
                if (item) {
                  changeQty(item, -1).then();
                }

                if (props.onChangeQty) {
                  props.onChangeQty(-1);
                }
              } else {
                if (props.onRemove) {
                  props.onRemove();
                }
              }
            }}
          />
        }
      </div>
    </IonItem>
  );
};

export default CartListItem;
