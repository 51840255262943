import {IonItemDivider, useIonModal, useIonRouter} from '@ionic/react';
import {useEffect, useState} from 'react';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import MWLoader from '../../Components/MWLoader/MWLoader';
import UseToggle from '../../Helpers/Hooks/UseToggle';
import {UserStore} from '../User/UserStore';
import CartListItem from './CartItem';
import CartItem from './CartItemClass';
import {
  CartStore,
  CartStoreInit,
  changeQty,
  getCartSubTotal,
  removeFromCart,
} from './CartStore';
import CheckoutButton from './CheckoutButton';
import EmptyCartMessage from './EmptyCartMessage';
import {CartItemProps} from './types';
import {ProductModal} from '../Product/ProductModal';
import {loadProduct} from '../Product/ProductStore';

const CartItems = ({...props}: CartItemProps) => {
  const router = useIonRouter();
  const subtotal = getCartSubTotal();
  const userState = UserStore.useState((s) => s);
  const cartState = CartStore.useState((s) => s);
  const [showCheckout, setShowCheckout] = useState(false);
  const [loading, toggleLoading] = UseToggle(false);
  const [notWatchedItems, setNotWatchedItems] = useState(true);
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });

  const localChangeQty = async (item: CartItem, amount: number) => {
    toggleLoading(true);
    await changeQty(item, amount)
    await CartStoreInit();
    toggleLoading(false);
  }

  useEffect(() => {
    let checkForOnlyWatchedItems = cartState.items.filter((item: CartItem) => {
      return !item.watching;
    });
    if (checkForOnlyWatchedItems.length > 0) {
      setNotWatchedItems(false);
    } else {
      setNotWatchedItems(true);
    }
  }, [cartState, notWatchedItems]);

  return (
    <div className='relative'>
      {cartState.items.length > 0 && (
        <div className='hidden lg:block p-4'>
          <div className='flex flex-col items-center justify-center'>
            <CheckoutButton
              disabled={!userState.hubs?.length || notWatchedItems}
              subtotal={subtotal}
              onClick={() => setShowCheckout(true)}
            />
          </div>
        </div>
      )}

      {cartState.items.length === 0 && (
        <EmptyCartMessage dismiss={props.dismiss}/>
      )}

      <div className={` mw-cart-items divide-y-2 pb-28 ${props.className || ''}`}>
        {/* Loop over Not Watching Items  */}
        {cartState.items.filter((i: any) => !i.watching && !i.product.isPreOrder).map((item: CartItem) => {
          return (
            <CartListItem
              key={item.id}
              item={item}
              onOpenProduct={() => {
                loadProduct(item.product).then();
                presentProductModal();
              }}
              onRemove={() => {
                removeFromCart(item).then();
              }} onChangeQty={(qty: number) => {
              localChangeQty(item, qty).then();
            }}
            />);
        })}

        {/* Show Watching only
        This will fileter the list by watching:true  */}

        {cartState.items.filter(i => i.watching).length > 0 &&
          <IonItemDivider>
            <h2>Watching</h2>
          </IonItemDivider>}

        {cartState.items.filter(i => i.watching).map((item: CartItem) => {
          // Return the list item
          return (
            <CartListItem
              key={item.id}
              item={item}
              onOpenProduct={() => {
                loadProduct(item.product).then();
                presentProductModal();
              }}
              onRemove={() => {
                removeFromCart(item).then();
              }}
              onChangeQty={(qty: number) => {
                localChangeQty(item, qty).then();
              }}
            />
          );
        })}

        {cartState.items.filter((i: any) => i.product.isPreOrder).length > 0 &&
          <IonItemDivider>
            <h2>Preorders</h2>
          </IonItemDivider>}

        {cartState.items.filter((i: any) => i.product.isPreOrder).map((item: any) => {
          return (
            <CartListItem
              key={item.id}
              item={item}
              onOpenProduct={() => {
                loadProduct(item.product).then();
                presentProductModal();
              }}
              onRemove={() => {
                removeFromCart(item).then();
              }}
              onChangeQty={(qty: number) => {
                localChangeQty(item, qty).then();
              }}
            />
          );
        })}
      </div>

      <IFrameModalController
        title={'Checkout'}
        url={`${process.env.REACT_APP_CHECKOUT_URL}`}
        open={showCheckout}
        hideHeader={true}
        showCheckout={(val: boolean) => setShowCheckout(val)}
        router={router}
        showLoading={true}
      />

      {loading && <MWLoader/>}
    </div>
  );
};

export default CartItems;
